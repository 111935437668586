@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: url("https://db.onlinewebfonts.com/t/dc2e22aeb9024b30a058c690a7f9f7ba.eot");
  src: url("https://db.onlinewebfonts.com/t/dc2e22aeb9024b30a058c690a7f9f7ba.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/dc2e22aeb9024b30a058c690a7f9f7ba.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/dc2e22aeb9024b30a058c690a7f9f7ba.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/dc2e22aeb9024b30a058c690a7f9f7ba.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/dc2e22aeb9024b30a058c690a7f9f7ba.svg#Agustina")format("svg");
  font-display: swap;
}
/* We're loading Montserrat from Google Fonts via link tag */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: rem;
    line-height: normal;
  }
}
